import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

serviceWorkerRegistration.register();

const w3CssLink = document.createElement('link');
w3CssLink.rel = 'stylesheet';
w3CssLink.href = 'https://www.w3schools.com/w3css/4/w3.css';

const w3ThemeLink = document.createElement('link');
w3ThemeLink.rel = 'stylesheet';
w3ThemeLink.href = 'https://www.w3schools.com/lib/w3-theme-black.css';

const fontAwesomeLink = document.createElement('link');
fontAwesomeLink.rel = 'stylesheet';
fontAwesomeLink.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.3.0/css/font-awesome.min.css';

const googleClint = document.createElement('script');
googleClint.src = 'https://accounts.google.com/gsi/client';
googleClint.async = true;
googleClint.defer = true;

document.head.appendChild(w3CssLink);
document.head.appendChild(w3ThemeLink);
document.head.appendChild(fontAwesomeLink);
document.head.appendChild(googleClint);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
